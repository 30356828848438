import React, { lazy, Fragment, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Sidebar from 'components/Sidebar';
import AuthGuard from 'components/AuthGuard';
import GuestGuard from 'components/GuestGuard';
import Progress from 'components/Progress';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { ROLE_USER, ROLE_COLLECT, ROLE_COMMERCIAL, ROLE_TRUSTED, ROLE_ADMIN } from 'utils/constants/roles';

const Login = lazy(() => import('pages/Login'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const RecoveryPassword = lazy(() => import('pages/RecoveryPassword'));
const SelectMatrix = lazy(() => import('pages/SelectMatrix'));
const NoAccess = lazy(() => import('pages/SelectMatrix/NoAccess'));
const Invitation = lazy(() => import('pages/Invitation'));
const Page404 = lazy(() => import('pages/Page404'));
const InvalidLink = lazy(() => import('components/InvalidLink'));

const CollectsOverdueList = lazy(() => import('pages/CollectsOverdueList'));
const UsersNew = lazy(() => import('pages/UsersNew'));
const UsersDetail = lazy(() => import('pages/UsersDetail'));
const UsersList = lazy(() => import('pages/UsersList'));
const WhoIsWhoList = lazy(() => import('pages/WhoIsWhoList'));
const WhoIsWhoNew = lazy(() => import('pages/WhoIsWhoNew'));
const WhoIsWhoDetail = lazy(() => import('pages/WhoIsWhoDetail'));
const CollectsNew = lazy(() => import('pages/CollectsNew'));
const CollectsScheduled = lazy(() => import('pages/CollectsScheduled'));
const TicketList = lazy(() => import('pages/TicketsList'));
const TicketDetail = lazy(() => import('pages/TicketDetail'));
const CollectsPrint = lazy(() => import('pages/CollectsPrint'));
const CollectsScheduledPrint = lazy(() => import('pages/CollectsScheduled/CollectsScheduledPrint'));
const Profile = lazy(() => import('pages/Profile'));
const DelayedDeliveries = lazy(() => import('pages/DelayedDeliveries'));
const ComingSoon = lazy(() => import('pages/ComingSoon'));

export const Routes = ({ routes = [] }) => {
  const { member } = useAuth();
  return (
    <Suspense fallback={<Progress />}>
      <Switch>
        {routes.map((route, i) => {
          const index = i;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const hasPermission = route.permission ? !!route.permission.includes(member.role) : true;

          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard path={route.path}>
                  <Layout>
                    {!!hasPermission && !!Component && <Component {...props} />}
                    {route.routes && <Routes routes={route.routes} />}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/',
    component: () => <Redirect to="/app/coletas/novas" />
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: Login
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/esqueceu-sua-senha',
    component: ForgotPassword
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/criar-nova-senha/:encrypted_code?',
    component: RecoveryPassword
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/convite/:encrypted_code?',
    component: Invitation
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/not-found',
    component: Page404
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/sem-acesso',
    component: NoAccess
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/link-invalido',
    component: InvalidLink
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/selecionar-transportadora',
    component: SelectMatrix
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: Sidebar,
    routes: [
      {
        exact: true,
        path: '/app/perfil',
        component: Profile,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/coletas/novas',
        component: CollectsNew,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/coletas/agendadas',
        component: CollectsScheduled,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/coletas-agendadas/impressao/:date',
        component: CollectsScheduledPrint,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/coletas/impressao/:id',
        component: CollectsPrint,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/coletas-atrasadas',
        component: CollectsOverdueList,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/entregas-atrasadas',
        component: DelayedDeliveries,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/central-de-atendimento',
        component: TicketList,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/ticket/:id',
        component: TicketDetail,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/usuarios',
        component: UsersList,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/usuarios/novo',
        component: UsersNew,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/usuarios/:id',
        component: UsersDetail,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/quem-e-quem',
        component: WhoIsWhoList,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/quem-e-quem/novo',
        component: WhoIsWhoNew,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/quem-e-quem/:id',
        component: WhoIsWhoDetail,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: ComingSoon,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/relatorios',
        component: ComingSoon,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/tabelas-de-frete/precos',
        component: ComingSoon,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/tabelas-de-frete/locais-de-origem',
        component: ComingSoon,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/configuracoes/dados-cadastrais',
        component: ComingSoon,
        permission: [ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/configuracoes/restricoes',
        component: ComingSoon,
        permission: [ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/configuracoes/tda-trt',
        component: ComingSoon,
        permission: [ROLE_ADMIN]
      },
      {
        exact: true,
        path: '/app/configuracoes/integracoes',
        component: ComingSoon,
        permission: [ROLE_ADMIN]
      },
      {
        path: '/',
        component: () => <Redirect to="/app/coletas/novas" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

Routes.defaultProps = {
  routes: []
};

Routes.propTypes = {
  routes: PropTypes.arrayOf({
    exact: PropTypes.bool,
    guard: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        exact: PropTypes.bool,
        path: PropTypes.string,
        component: PropTypes.node
      })
    )
  })
};

export default routes;
