import {
  mdiCog,
  mdiDolly,
  mdiTable,
  mdiFinance,
  mdiHumanDolly,
  mdiCircleSmall,
  mdiChartBarStacked,
  mdiAccountMultiple,
  mdiCardAccountPhone,
  mdiCommentTextOutline,
  mdiArchiveClock,
  mdiTruck
} from '@mdi/js';
import { ROLE_USER, ROLE_COLLECT, ROLE_COMMERCIAL, ROLE_TRUSTED, ROLE_ADMIN } from 'utils/constants/roles';

const sections = [
  {
    id: 1,
    subheader: 'COLETAS',
    permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN],
    items: [
      {
        id: 1,
        icon: mdiDolly,
        title: 'Novas Solicitações',
        href: '/app/coletas/novas',
        info: data => data?.collects_count,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        id: 2,
        icon: mdiHumanDolly,
        title: 'Coletas Agendadas',
        href: '/app/coletas/agendadas',
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      }
    ]
  },
  {
    id: 2,
    subheader: 'ATRASOS',
    new: true,
    permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN],
    items: [
      {
        id: 1,
        icon: mdiArchiveClock,
        title: 'Coletas Atrasadas',
        href: '/app/coletas-atrasadas',
        info: data => data?.overdue_collects_count,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        id: 2,
        icon: mdiTruck,
        title: 'Entregas Atrasadas',
        href: '/app/entregas-atrasadas',
        info: data => data?.delayed_deliveries_count,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      }
    ]
  },
  {
    id: 3,
    subheader: 'CENTRAL DE ATENDIMENTO',
    permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN],
    items: [
      {
        id: 1,
        icon: mdiCommentTextOutline,
        title: 'Central de Atendimento',
        href: '/app/central-de-atendimento',
        info: data => data?.tickets_count,
        permission: [ROLE_USER, ROLE_COMMERCIAL, ROLE_COLLECT, ROLE_TRUSTED, ROLE_ADMIN]
      }
    ]
  },
  {
    id: 4,
    subheader: 'PESSOAS',
    permission: [ROLE_TRUSTED, ROLE_ADMIN],
    items: [
      {
        id: 1,
        icon: mdiAccountMultiple,
        href: '/app/usuarios',
        title: 'Usuários',
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        id: 2,
        href: '/app/quem-e-quem',
        title: 'Quem é Quem',
        icon: mdiCardAccountPhone,
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      }
    ]
  },
  {
    id: 5,
    subheader: 'RELATÓRIOS',
    permission: [ROLE_TRUSTED, ROLE_ADMIN],
    items: [
      {
        id: 1,
        icon: mdiFinance,
        title: 'Dashboard',
        href: '/app/dashboard',
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      },
      {
        id: 2,
        icon: mdiChartBarStacked,
        title: 'Relatórios',
        href: '/app/relatorios',
        permission: [ROLE_TRUSTED, ROLE_ADMIN]
      }
    ]
  },
  {
    id: 6,
    subheader: 'CONFIGURAÇÕES DA ORIGEM',
    permission: [ROLE_TRUSTED, ROLE_ADMIN],
    items: [
      {
        id: 1,
        title: 'Tabelas de Frete',
        icon: mdiTable,
        href: '/app/tabelas-de-frete',
        permission: [ROLE_TRUSTED, ROLE_ADMIN],
        items: [
          {
            id: 1,
            icon: mdiCircleSmall,
            href: '/app/tabelas-de-frete/precos',
            title: 'Preços',
            permission: [ROLE_TRUSTED, ROLE_ADMIN]
          },
          {
            id: 2,
            icon: mdiCircleSmall,
            href: '/app/tabelas-de-frete/locais-de-origem',
            title: 'Locais de Origem',
            permission: [ROLE_TRUSTED, ROLE_ADMIN]
          }
        ]
      }
    ]
  },
  {
    id: 7,
    subheader: 'CONFIGURAÇÕES DA TRANSPORTADORA',
    permission: [ROLE_ADMIN],
    items: [
      {
        id: 3,
        title: 'Configurações',
        icon: mdiCog,
        href: '/app/configuracoes',
        permission: [ROLE_ADMIN],
        items: [
          {
            id: 1,
            icon: mdiCircleSmall,
            title: 'Dados Cadastrais',
            href: '/app/configuracoes/dados-cadastrais',
            permission: [ROLE_ADMIN]
          },
          {
            id: 2,
            icon: mdiCircleSmall,
            title: 'Restrições',
            href: '/app/configuracoes/restricoes',
            permission: [ROLE_ADMIN]
          },
          {
            id: 3,
            icon: mdiCircleSmall,
            title: 'TDA/TRT',
            href: '/app/configuracoes/tda-trt',
            permission: [ROLE_ADMIN]
          },
          {
            id: 4,
            icon: mdiCircleSmall,
            title: 'Integrações',
            href: '/app/configuracoes/integracoes',
            permission: [ROLE_ADMIN]
          }
        ]
      }
    ]
  }
];

export default sections;
